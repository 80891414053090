.has-error {
    .has-error-input {
        color: red;
        display: flex;
    }
    .bar {border: 1px solid red;}

}

.fYDAFi {
    top: 10px !important;
    right: 180px !important;
    z-index: 10;
    position: absolute !important;
    box-shadow: unset !important;
    min-width: 37px !important;
    min-height: 36px !important;
}
.fYDAFi.secondary {
    right: 240px !important;
    background: rgb(87,192,49);
    min-width: 37px !important;
    min-height: 36px!important;
}

.path {
    padding: 7px;
    margin: 10px 0;
    background: white;
    font-size: 12px;
}
.breadcrumb{
    background: white;
}
.breadcrumb-item + .breadcrumb-item::before {
    content: ">";
}

.breadcrumb-item {
    color: #738ea5;
    cursor: pointer;
}
.breadcrumb-item:hover {
    text-decoration: underline;
}

.add-question {
    top: 171px !important;
    right: 160px !important;
}
/*
.question-form
.actions_right {
    display: flex;
    .round-button--is-secondary{
        margin-left: 220px !important;
    }
    .round-button--is-success{
        margin-left: 48px !important;
    }
}
*/
